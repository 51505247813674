@font-face {
    font-family: 'Gosha Sans';
    src:    url('assets/fonts/PPGoshaSans-Regular.woff2'),
            url('assets/fonts/PPGoshaSans-Regular.woff');
}
@font-face {
    font-family: 'Neue Montreal';
    src:    url('assets/fonts/PPNeueMontreal-Medium.woff2'),
            url('assets/fonts/PPNeueMontreal-Medium.woff');
}
@font-face {
    font-family: 'Mondwest';
    src:    url('assets/fonts/PPMondwest-Regular.woff2'),
            url('assets/fonts/PPMondwest-Regular.woff');
}


* {
    /* border: 1px solid black; */
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-family: 'Neue Montreal';
}


.Portfolio {
    display: flex;
    justify-content: center;
    background: linear-gradient(270deg, rgba(25,11,255,1) 0%, rgba(17,90,255,1) 65%, rgba(0,151,255,1) 100%);
    color: #F5F5F5;
    border-color: #F5F5F5;
}

.content {
    display: flex;
    gap: 50px;
    margin-top: 10vh;
    max-width: 90vw;
    min-height: 1000px;
}


.profile {
    min-width: 350px;
}
.intro-panel {
    height: 80vh;
    position: fixed;
    display: flex;
    flex-direction: column;
}
.intro-greeting {
    font-size: 1.2em;
    margin-bottom: 5px;
    margin-left: -8px;
}
.intro-greeting span {
    font-size: 1.6em;
}
.full-name {
    font-family: 'Mondwest';
    font-size: 75px;
    margin-bottom: 10px;
}
.bio {
    font-size: 18px;
}

.shortcuts {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    flex: 1;
}
.shortcut-link {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    max-width: fit-content;
    cursor: pointer;
}
.shortcuts span {
    display: inline-block;
    font-family: 'Gosha Sans';
    font-size: 20px;
    padding: 2px 2px 2px 0;
    transition: transform 0.3s ease;
}
.shortcut-arrow {
    position: absolute;
    left: -28px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}
.shortcut-link:hover .shortcut-arrow {
    display: inline-block;
    opacity: 1;    
}
.shortcut-link:hover span {
    transform: translateX(5px);
    transition: transform 0.3s ease-in-out;
}

.location {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.location-pin {
    margin-right: 5px;
}

.ext-links {
    display: flex;
    align-items: center;
    width: 100%;
    align-self: flex-end;
}
.social-link {
    margin-left: 5px;
    max-height: 32px;
    margin-bottom: 8px;
    transition: transform 0.1s ease-in-out;
    color: #F5F5F5;
}
.social-link:hover {
    transform: scale(1.2);
}
.CV-download {
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 20px;
    margin-right: 10px;
    border: 2px solid #F5F5F5;
    border-radius: 999px;
    padding: 5px 20px 5px 20px;
    transition: transform 0.1s ease-in-out;
}
.CV-download:hover {
    /* background: rgba(255, 255, 255, 0.1); */
    transform: scale(1.05);
}
.CV-download p {
    margin-left: 5px;
    color: #F5F5F5;
}



.resume {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.section {
    margin-bottom: 100px;
}
.section-header {
    font-family: 'Gosha Sans';
    font-weight: 400;
    font-size: x-large;
    width: 100%;
    text-align: end;
    border-bottom: 2px solid;
    padding-bottom: 5px;
    margin-bottom: 40px;
}

.timeline {
    position: relative;
    margin: 60px 0 0 20px;
    display: flex;
    flex-direction: column;
    gap: 38px;
}
.timeline::before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 0;
    width: 4px;
    background-color: #f5f5f5;
}
.timeline-event {
    display: flex;
    flex-direction: column;
}
.timeline-circle {
    position: absolute;
    left: -8px;
    width: 12px;
    height: 12px;
    background: #F5F5F5;
    border: 4px solid #F5F5F5;
    border-radius: 50%;
    z-index: 1;
    transform: translateY(-50%);
}
.timeline-date {
    position: absolute;
    left: 25px;
    transform: translateY(-50%);
}
.exp-content {
    display: flex;
    flex-direction: column;
    margin: 15px 0 0 25px;
}
.exp-name {
    margin-top: 0;
    font-size: 18px;
}
.exp-place {
    font-style: italic;
}
.exp-desc {
    margin: 7px 0 15px 0;
}
.skill-tags {
    list-style: none;
}
.skill-tags li {
    display: inline-block;
    border: 1px solid #f5f5f5;
    padding: 2px 5px 2px 5px;
    margin: 0 8px 8px 0;
    font-family: 'Gosha Sans';
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
}

.project:not(:first-of-type) {
    margin-top: 32px;
}
.project-desc {
    margin: 5px 0 15px 0;
}



/* copy n pasted from app.css */
.contact-form input,
.contact-form textarea {
    width: 100%;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1);
    color: #F5F5F5;
}
.contact-form input::placeholder, 
.contact-form textarea::placeholder {
    color: #D3D3D3;
}
.contact-form input:-webkit-autofill, 
.contact-form textarea:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #F5F5F5;
    box-shadow: inset 100em 0 0 0 rgba(255, 255, 255, 0.1);
    caret-color: #F5F5F5;
}
.contact-form input {
    height: 40px;
    border-radius: 999px;
    padding: 5px 15px 5px 15px;
}
.contact-form textarea {
    min-height: 96px;
    height: auto;
    border-radius: 16px;
    padding: 10px 15px 10px 15px;
    resize: vertical;
}
.contact-form 
input:focus,
textarea:focus {
    outline: none;
}

.contact-form button {
    border: none;
    cursor: pointer;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border: 2px solid #F5F5F5;
    border-radius: 999px;
    padding: 10px 20px 10px 20px;
    background: none;
    transition: transform 0.1s ease-in-out;
}
.contact-form button:hover {
    transform: scale(1.1);
    /* background: rgba(255, 255, 255, 0.1); */
}
.contact-form button > p {
    font-size: 18px;
    margin-left: 5px;
    color: #F5F5F5;
}
.toast-container {
    width: 100%;
    font-size: small;
    color: #292929;
}



/* tablets and mobile devices */
@media screen and (max-width: 950px) {
    .Portfolio {
        width: 100%;
        background: linear-gradient(0deg, rgba(25,11,255,1) 0%, rgba(17,90,255,1) 65%, rgba(0,151,255,1) 100%);
    }
    .content {
        flex-direction: column;
        min-width: 90vw;
    }
    .profile {
        margin-bottom: 70px;
        width: 100%;
    }
    .intro-panel {
        height: fit-content;
        position: static;
        font-size: 22px;
    }
    .intro-panel div {
        position: static;
        margin-bottom: 70px;
    }
    .full-name {
        font-size: 15vw;
    }
    .bio {
        font-size: 22px;
    }
    .shortcuts {
        display: none;
    }
    .location {
        font-size: 22px;
    }
    .location-pin {
        width: 30px;
        height: 30px;
    }
    .CV-download {
        font-size: 22px;
    }

    .resume {
        min-width: 100%;
        font-size: 22px;
    }
    .section-header {
        font-size: 30px;
        position: sticky;
        top: 0;
        padding-top: 10px;
        backdrop-filter: blur(3px);
        z-index: 1000;
        margin-bottom: 60px;
    }
    .timeline {
        gap: 50px;
        margin-top: 80px;
    }
    .exp-name {
        font-size: 26px;
    }
    .skill-tags li {
        font-size: 18px;
    }

    .contact-form input {
        height: 60px;
        font-size: 18px;
        padding: 10px 20px 10px 20px;
    }
    .contact-form input::placeholder {
        font-size: 18px;
    }
    .contact-form textarea {
        height: 150px;
        font-size: 18px;
        padding: 20px;
        border-radius: 30px;
        resize: none;
    }
    .contact-form button {
        height: 50px;
        padding: 20px;
        transition: none;
    }
    .contact-form button > p{
        font-size: 20px;
        margin-left: 8px;
    }
    .contact-form .submit-icon {
        width: 24px;
        height: 24px;
    }
}

/* mobile devices */
@media screen and (max-width: 700px) {
    .full-name {
        font-size: 19vw;
    }
    .intro-greeting {
        font-size: 20px;
    }
    .bio {
        font-size: 16px;
    }
    .location {
        font-size: 18px;
    }
    .CV-download {
        font-size: 18px;
    }

    .resume {
        font-size: 16px;
    }
    .section-header, .shortcuts * {
        font-size: 22px;
    }
    
    .timeline::before,
    .timeline-circle {
        display: none;
    }
    .timeline-date {
        position: static;
    } 
    .timeline {
        margin-left: 0;
    }
    .exp-content {
        margin: 0;
    }
    .exp-name {
        font-size: 22px;;
    }
    .exp-place {
        font-size: 18px;
    }
    .skill-tags li {
        font-size: 16px;
    }

    .contact-form button {
        height: 40px;
        padding: 20px 15px 20px 15px;
    }
    .contact-form button > p{
        font-size: 16px;
        margin-left: 8px;
    }
    .contact-form .submit-icon {
        width: 22px;
        height: 22px;
    }
}

/* large screens */
@media screen and (min-width: 1280px) {
    .content {
        min-width: 1300px;
        gap: 120px;
    }
    .profile {
        width: 500px;
    }
    .full-name {
        font-size: 100px;
    }
}